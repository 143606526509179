@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/icomoon.eot');
  src: url('../../fonts/icomoon.eot#iefix') format('embedded-opentype'), url('../../fonts/icomoon.ttf') format('truetype'),
    url('../../fonts/icomoon.woff') format('woff'), url('../../fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-case-study:before {
  content: "\e900";
}
.icon-white-paper-icon:before {
  content: "\e901";
}
.icon-news-icon:before {
  content: "\e902";
}
.icon-drag-indicator:before {
  content: "\e903";
}
.icon-video-quiz:before {
  content: "\e904";
}
.icon-mute:before {
  content: "\e905";
}
.icon-unmute:before {
  content: "\e906";
}
.icon-pause:before {
  content: "\e907";
}
.icon-play:before {
  content: "\e908";
}
.icon-web-stories:before {
  content: "\e909";
}
.icon-poll:before {
  content: "\e90a";
}
.icon-widget:before {
  content: "\e90b";
}
.icon-double-arrow-top:before {
  content: "\e90c";
}
.icon-copy:before {
  content: "\e90d";
}
.icon-campaign:before {
  content: "\e90e";
}
.icon-visibility-off:before {
  content: "\e90f";
}
.icon-visibility:before {
  content: "\e910";
}
.icon-add:before {
  content: "\e911";
}
.icon-arrow-drop-down:before {
  content: "\e912";
}
.icon-arrow-drop-up:before {
  content: "\e913";
}
.icon-chevron-left:before {
  content: "\e914";
}
.icon-chevron-right:before {
  content: "\e915";
}
.icon-close:before {
  content: "\e916";
}
.icon-create:before {
  content: "\e917";
}
.icon-dashboard:before {
  content: "\e918";
}
.icon-delete:before {
  content: "\e919";
}
.icon-download:before {
  content: "\e91a";
}
.icon-feed:before {
  content: "\e91b";
}
.icon-filter-list:before {
  content: "\e91c";
}
.icon-help:before {
  content: "\e91d";
}
.icon-home:before {
  content: "\e91e";
}
.icon-notifications:before {
  content: "\e91f";
}
.icon-settings:before {
  content: "\e920";
}
.icon-sidebar:before {
  content: "\e921";
}
.icon-sort:before {
  content: "\e922";
}
.icon-account:before {
  content: "\e923";
}
.icon-check:before {
  content: "\e924";
}
.icon-chevron-down:before {
  content: "\e925";
}
.icon-chevron-up:before {
  content: "\e926";
}
.icon-lock:before {
  content: "\e927";
}
.icon-logout:before {
  content: "\e928";
}
.icon-add-photo:before {
  content: "\e929";
}
.icon-id-card:before {
  content: "\e92a";
}
.icon-image:before {
  content: "\e92b";
}
.icon-info:before {
  content: "\e92c";
}
.icon-account-fill:before {
  content: "\e92d";
}
.icon-add-circle:before {
  content: "\e92e";
}
.icon-upload:before {
  content: "\e92f";
}
.icon-comment:before {
  content: "\e930";
}
.icon-image-1:before {
  content: "\e931";
}
.icon-send:before {
  content: "\e932";
}
.icon-language:before {
  content: "\e933";
}
.icon-comment-rounded:before {
  content: "\e934";
}
.icon-dots-verticle:before {
  content: "\e935";
}
.icon-lock-close:before {
  content: "\e936";
}
.icon-more-horizontal:before {
  content: "\e937";
}
.icon-forum:before {
  content: "\e938";
}
.icon-add-circle1:before {
  content: "\e939";
}
.icon-cancel:before {
  content: "\e93a";
}
.icon-remove:before {
  content: "\e93b";
}
.icon-schedule:before {
  content: "\e93c";
}
.icon-amp:before {
  content: "\e93d";
}
.icon-desktop:before {
  content: "\e93e";
}
.icon-mobile:before {
  content: "\e93f";
}
.icon-tablet:before {
  content: "\e940";
}
.icon-bat:before {
  content: "\e941";
}
.icon-wk:before {
  content: "\e942";
}
.icon-merge:before {
  content: "\e943";
}
.icon-baseball:before {
  content: "\e944";
}
.icon-share:before {
  content: "\e945";
}
.icon-file:before {
  content: "\e946";
}
.icon-calendar:before {
  content: "\e947";
}
.icon-imgPoll:before {
  content: "\e948";
}
.icon-mediaPoll:before {
  content: "\e949";
}
.icon-minus:before {
  content: "\e94a";
}
.icon-reactionPoll:before {
  content: "\e94b";
}
.icon-simplePoll:before {
  content: "\e94c";
}
.icon-vsPoll:before {
  content: "\e94d";
}
.icon-podcast:before {
  content: "\e94e";
}
.icon-touch-interaction:before {
  content: "\e94f";
}
.icon-refresh:before {
  content: "\ea2e";
}
