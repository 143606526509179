.react-select {
  &.select--is-disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: auto;
    .select__control {
      cursor: not-allowed;
    }
  }
  &.on-hover {
    .select__menu {
      display: none;
      margin: 0;
    }
    &:hover .select__menu {
      display: block;
    }
  }
  .select__control {
    min-height: 38px;
    background-color: var(--secondary-700);
    border-color: transparent;
    border-radius: 8px;
    box-shadow: none;
    // var(--secondary-500)
    &:hover {
      border-color: transparent;
    }
    &.select__control--is-focused {
      border-color: var(--secondary-500);
    }
  }
  &.error .select__control {
    border-color: #e64343;
  }
  .select__placeholder {
    font-size: 14px;
    color: var(--secondary-500);
  }
  .select__input {
    color: var(--secondary-300) !important;
    // font-size: 14px !important;
  }
  .select__multi-value {
    background-color: var(--secondary-800);
    .select__multi-value__label {
      color: var(--secondary-300);
    }
  }
  .select__single-value {
    font-size: 14px;
    color: var(--secondary-300);
  }
  .select__menu {
    z-index: 10;
  }
  .select__menu-list {
    background-color: var(--secondary-700);
    .select__option {
      color: var(--secondary-100);
      font-size: $fs-14;
      background-color: transparent;
      cursor: pointer;
      &:not(.select__option--is-disabled):hover,
      &.select__option--is-selected {
        background-color: var(--secondary-800);
      }
      &:not(.select__option--is-selected).select__option--is-disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
      &.select__option--is-focused {
        background-color: var(--secondary-600);
      }
    }
  }
  &.only-border .select__control {
    border-color: var(--secondary-500);
    background-color: transparent;
  }
  &.sm .select__control {
    min-height: 27px;
    border-radius: 4px;
    .select__single-value {
      font-size: $fs-13;
    }
    .select__dropdown-indicator {
      padding: 2px 6px;
    }
  }
  &.ex-sm .select__control {
    min-height: 20px;
    min-width: 67px;
    .select__single-value {
      font-size: $fs-12 - 1;
      text-align: center;
    }
    .select__value-container {
      padding: 0px 7px;
    }
    .select__input-container {
      margin: 0;
    }
    .select__dropdown-indicator {
      padding: 0 3px;
    }
  }
}
