.input-array-box {
  border: 1px solid var(--secondary-700);
  border-radius: 8px;
  margin-right: 25px;
  padding: 20px 15px;
  .right-btn {
    background-color: var(--secondary-700);
    border-radius: 0 8px 8px 0;
    left: 100%;
    top: 5px;
    .btn {
      font-size: 16px;
      padding: 5px 4px 4px;
    }
  }
  & + .input-array-box {
    margin-top: 15px;
  }
}
