.auth-main {
  min-height: 100vh;
  padding: 48px;
  position: relative;
  overflow: hidden;
  &:before {
    content: "";
    background-color: $primary-900;
    width: 156px;
    height: 156px;
    border-radius: 100%;
    top: -53px;
    right: -55px;
    position: absolute;
  }
  &:after {
    content: "";
    width: 122px;
    height: 122px;
    border-radius: 100%;
    border: 17px solid $primary-900;
    box-sizing: content-box;
    position: absolute;
    bottom: -78px;
    left: 402px;
  }
  .child-box {
    background-color: var(--secondary-800);
    width: 100%;
    max-width: 432px;
    padding: 37px 24px 24px;
    border-radius: 24px;
    border-bottom: 10px solid $primary-400;
    position: relative;
    z-index: 2;
    min-height: 445px;
    margin: 0 auto;
  }
  @include media-max(xl) {
    padding: 25px;
    .child-box {
      max-width: 360px;
    }
  }
  @include media-max(lg) {
    justify-content: center;
    .child-box {
      max-width: 400px;
    }
  }
  @include media-max(md) {
    .child-box {
      padding: 30px 20px 20px;
      min-height: auto;
    }
  }
  @include media-max(sm) {
    padding: 20px 15px;
    .child-box {
      padding: 20px 15px 15px;
      border-radius: 18px;
    }
    &:after {
      left: calc(100% - 76px);
    }
  }
}

%img {
  border-radius: 100%;
  overflow: hidden;
  opacity: 0.6;
  position: absolute;
}

@keyframes flickerAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
