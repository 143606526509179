// common
.cursor-pointer {
  cursor: pointer;
}

.subtitle-color {
  color: #4574a4;
}

.primary-color {
  color: $primary-300;
}

.date-txt {
  font-size: 10px;
}

.date-val {
  font-size: 14px;
}

.date-range-wrapper {
  border: 1px solid $primary-300;
  border-radius: 15px;
  .from-date-wrapper {
    border-right: 1px solid $primary-300;
  }
  .reset { }
}
.sDashFilter {
  .react-datepicker__input-container,
  .react-datepicker-wrapper {
    display: block;
  }
  .date-range-wrapper {
    border-radius: 8px;
    img { width: 22px; height: 22px; }
  }
  .date-val { font-size: 12px;}
  .reset-icon { width: 24px;}
}

// count cards
.count-card-list {
  .count-card-wrapper {
    background: $primary-300;
    min-width: 220px;
    flex: 1;
  }
}

.count-card-wrapper {
  border-radius: 15px;
  background-color: var(--secondary-800);
  min-height: 100%;
  .title {
    font-size: 32px;
    color: #213b55;
  }
  .count {
    font-size: 52px;
  }
}

// task status list
.task-status-list-wrapper {
  .status {
    font-size: 32px;

    &.yellow {
      border-bottom: 5px solid #ffd747;
    }
    &.green {
      border-bottom: 5px solid #30fed3;
    }
    &.purple {
      border-bottom: 5px solid #9747ff;
    }
    &.primary {
      border-bottom: 5px solid $primary-300;
    }

    .status-count {
      font-size: 52px;
      margin-bottom: -12px;
    }
  }

  .list {
    margin-top: 46px;
  }

  .task-card {
    &.yellow {
      border: 1px solid #ffd747;
    }
    &.green {
      border: 1px solid #30fed3;
    }
    &.purple {
      border: 1px solid #9747ff;
    }
    &.primary {
      border: 1px solid $primary-300;
    }
    border-radius: 15px;

    .task-time {
      font-size: 10px;
      font-weight: 700;
    }

    .task-title {
      font-size: 10px;
      &.yellow {
        color: #ffd747;
      }
      &.green {
        color: #30fed3;
      }
      &.purple {
        color: #9747ff;
      }
      &.primary {
        color: $primary-300;
      }
    }

    .task-subtitle {
      font-size: 18px;
    }
  }
}
